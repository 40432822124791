import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import CTA from './CTA';

import '../styles/newsList.less';

interface MarkdownRemark {
    allMarkdownRemark: {
        edges: MarkdownEdge[];
    };
}

interface MarkdownEdge {
    node: {
        id: string;
        frontmatter: {
            date: Date;
            slug: string;
            title: string;
            excerpt: string;
            featuredImage: {
                childImageSharp: {
                    gatsbyImageData: IGatsbyImageData;
                };
            };
        };
    };
}

interface NewsListParams {
    title: string;
    hasCta?: boolean;
}

const NewsList = ({ title, hasCta = false }: NewsListParams): JSX.Element => {
    const data: MarkdownRemark = useStaticQuery(graphql`
        {
            allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, filter: { frontmatter: { type: { eq: "news" } } }, limit: 3) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "MMMM DD, YYYY")
                            slug
                            title
                            excerpt
                            featuredImage {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const edges: MarkdownEdge[] = data.allMarkdownRemark.edges;
    const newsList = edges
        .filter((edge: MarkdownEdge) => !!edge.node.frontmatter.date)
        .map((edge: MarkdownEdge) => {
            const path = `/news/${edge.node.frontmatter.slug}/`;
            return (
                <Link to={path} className="item" key={edge.node.id}>
                    <GatsbyImage image={edge.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt={edge.node.frontmatter.title} />
                    <h2 className="item__title">{edge.node.frontmatter.title}</h2>
                    <p className="item__excerpt">{edge.node.frontmatter.excerpt}</p>
                </Link>
            );
        });

    return (
        <div className="news-list">
            <h2 className="news-list__title">{title}</h2>
            <div className="news-list__list">{newsList}</div>
            {hasCta && (
                <div className="news-list__cta">
                    <CTA classname="action" path="/news/" text="Read all news" />
                </div>
            )}
        </div>
    );
};

export default NewsList;
