import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import NewsList from '../components/NewsList';
import NewsListAll from '../components/NewsListAll';

const NewsPage = (): JSX.Element => {
    return (
        <>
            <Helmet title="News" />
            <Layout>
                <div className="page page__lg">
                    <NewsList title="Recent news" />
                    <div className="page__cnt">
                        <NewsListAll title="All news" />
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default NewsPage;
